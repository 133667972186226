import React, { Component } from 'react';
export default class Footer extends Component {
  render() {
    return (
      <footer>
      <div className="grid-x">
        <div className="cell">
          <ul className="social-links">
          </ul>
        </div>
        <div id="go-top"><a className="smoothscroll" title="Back to Top" href="#home"><i className="icon-up-open" /></a></div>
      </div>
    </footer>
    );
  }
}