import React, { Component } from "react";
import Slider from "react-slick";

export default class SimpleSlider2 extends Component {
  render() {
     
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <div>
        <Slider {...settings}>
          <div>
            <img src="/images/pge-sample-site-01.jpg" alt="Perfect Golf Event 2.0 Event Site" />
          </div>
          <div>
            <img src="/images/pge-sample-site-02.jpg" alt="Perfect Golf Event 2.0 Event Site" />
          </div>
          <div>
            <img src="/images/pge-sample-site-03.jpg" alt="Perfect Golf Event 2.0 Event Site" />
          </div>
        </Slider>
      </div>
    );
  }
}