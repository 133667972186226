import React, { Component } from "react";
import Slider from "react-slick";

export default class SimpleSlider3 extends Component {
  render() {
     
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <div>
        <Slider {...settings}>
          <div>
            <img src="/images/ss-pgecom-01.jpg" alt="PerfectGolfEvent.com" />
          </div>
          <div>
            <img src="/images/ss-pgecom-02.jpg" alt="PerfectGolfEvent.com" />
          </div>
          <div>
            <img src="/images/ss-pgecom-03.jpg" alt="PerfectGolfEvent.com" />
          </div>
          <div>
            <img src="/images/ss-pgecom-04.jpg" alt="PerfectGolfEvent.com" />
          </div>
        </Slider>
      </div>
    );
  }
}