import React, { useState } from 'react'
import emailjs from 'emailjs-com'

const Contact = () => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const submit = () => {
    if (name && email && message) {
      const serviceId = 'service_9mqsir8';
      const templateId = 'template_kdfowub';
      const userId = 'user_FPb32DXh7L3blHg0PfXyy';
      const templateParams = {
          name,
          email,
          message
      };
      emailjs.send(serviceId, templateId, templateParams, userId)
          .then(response => console.log(response))
          .then(error => console.log(error));

      setName('');
      setEmail('');
      setMessage('');
      setEmailSent(true);
  } else {
        alert('Please fill in all fields.');
    }
  }
  const isValidEmail = email => {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
  };

  return (
      <div id="contact-section">
        <div className="grid-x">
          <div className="cell">
            <h2>Contact</h2>
            <div className="contact-form">
              <input type="text" name="name" placeholder="Your Name" value={name} onChange={e => setName(e.target.value)} />
              <input type="email" name="email" placeholder="Your email address" value={email} onChange={e => setEmail(e.target.value)} />
              <textarea name="message" placeholder="Your message" value={message} onChange={e => setMessage(e.target.value)}></textarea>
              <button onClick={submit}>Send Message</button>
              <span className={emailSent ? 'visible' : null}>Thank you for your message, I will respond via email very soon.</span>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Contact;

