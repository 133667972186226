import React, { Component } from "react";
import Slider from "react-slick";

export default class SimpleSlider5 extends Component {
  render() {
     
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <div>
        <Slider {...settings}>
          <div>
            <img src="/images/ss-te-01.jpg" alt="Tech Electronics Corporate Website" />
          </div>
          <div>
            <img src="/images/ss-te-02.jpg" alt="Tech Electronics Corporate Website" />
          </div>
          <div>
            <img src="/images/ss-te-03.jpg" alt="Tech Electronics Corporate Website" />
          </div>
        </Slider>
      </div>
    );
  }
}