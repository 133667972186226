import React, { useState, useEffect, Component } from 'react';



export default class Header extends Component {
  render() {
    let logo = this.props.logo;
    return (
      
        <header className="App-header">
            <div className="grid-x">
            <div className="cell small-8 medium-6 logo-cell">
                <a href="#root">
                    <img src="/images/mac-logo.png" className="App-logo" alt="logo" />
                </a>
            </div>
            <div className="cell small-4 medium-6">
                <nav id="nav-wrap">
                    <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
                    <a className="mobile-btn" href="#" title="Hide navigation">Hide navigation</a>
                    <ul id="nav" className="nav">
                        <li><a href="#work-section">Work</a></li>
                        <li><a href="#about-section">About</a></li>
                        <li><a href="#contact-section">Contact</a></li>
                    </ul>
                </nav>
            </div>
            </div>
        </header>
    );
  }
}

