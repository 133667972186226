import React, { Component } from 'react';
export default class Hero extends Component {
  render() {
    return (
      <div className="hero-full-screen" id="top">
        <div className="middle-content-section">
          <div className="hero-blurb">
            <h1>Michael Cozart</h1>
            <div className="grid-x">
              <div className="cell large-3">
                <img src="/images/mac-2.jpg" alt="Michael Cozart" />
              </div>
              <div className="cell large-9">
                <p>Based in the Tampa area, Michael Cozart is a self-motivated web developer/project manager with wide ranging experience in front and back-end web development technologies. Results oriented with a proven track record of collaboration, team leadership, and quantifiable results.</p>  
              </div>
            </div>
            <div className="grid-x">
              <div className="cell contact-info">
                <button className="button large"><a href="#work-section">See My Work</a></button>
                <button className="button large"><a href="#contact-section">Request Resume</a></button>
                <a href="https://www.linkedin.com/in/mcozart/" target="_blank" className="li"><img src="/images/linked-in.png" href="LinkedIn Profile" /></a>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-content-section show-for-large" data-magellan data-threshold="0">
          <a href="#work-section"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#ffffff"><path d="M24 12c0-6.627-5.373-12-12-12s-12 5.373-12 12 5.373 12 12 12 12-5.373 12-12zm-18.005-1.568l1.415-1.414 4.59 4.574 4.579-4.574 1.416 1.414-5.995 5.988-6.005-5.988z"/></svg></a>
        </div>
      </div>
    );
  }
}


