import React, { Component } from "react";
import Slider from "react-slick";

export default class SimpleSlider4 extends Component {
  render() {
     
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <div>
        <Slider {...settings}>
          <div>
            <img src="/images/ss-bw-01.jpg" alt="Beckwood Press Corporate Website" />
          </div>
          <div>
            <img src="/images/ss-bw-02.jpg" alt="Beckwood Press Corporate Website" />
          </div>
          <div>
            <img src="/images/ss-bw-03.jpg" alt="Beckwood Press Corporate Website" />
          </div>
          <div>
            <img src="/images/ss-bw-04.jpg" alt="Beckwood Press Corporate Website" />
          </div>
        </Slider>
      </div>
    );
  }
}