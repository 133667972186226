import React, { Component } from "react";
import Slider from "react-slick";

export default class SimpleSlider extends Component {
  render() {
     
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <div>
        <Slider {...settings}>
          <div>
            <img src="/images/ss-pge2-01.jpg" alt="Perfect Golf Event 2.0" />
          </div>
          <div>
            <img src="/images/ss-pge2-02.jpg" alt="Perfect Golf Event 2.0" />
          </div>
          <div>
            <img src="/images/ss-pge2-03.jpg" alt="Perfect Golf Event 2.0" />
          </div>
          <div>
            <img src="/images/ss-pge2-04.jpg" alt="Perfect Golf Event 2.0" />
          </div>
          <div>
            <img src="/images/ss-pge2-05.jpg" alt="Perfect Golf Event 2.0" />
          </div>
          <div>
            <img src="/images/ss-pge2-06.jpg" alt="Perfect Golf Event 2.0" />
          </div>
          <div>
            <img src="/images/ss-pge2-07.jpg" alt="Perfect Golf Event 2.0" />
          </div>
        </Slider>
      </div>
    );
  }
}