import React, { Component } from 'react';
import SimpleSlider1 from './SimpleSlider1';
import SimpleSlider2 from './SimpleSlider2';
import SimpleSlider3 from './SimpleSlider3';
import SimpleSlider4 from './SimpleSlider4';
import SimpleSlider5 from './SimpleSlider5';
import SimpleSlider6 from './SimpleSlider6';
import SimpleSlider7 from './SimpleSlider7';
import SimpleSlider8 from './SimpleSlider8';

export default class Work extends Component {
  render() {
    
    return (
        <div id="work-section">
            <h2>Work</h2>
            <div className="grid-x work-item">
                <div className="cell large-6">
                    <div className="inner-cell">
                        <h3>MLB Knowledge</h3>
                        <p>MLB Knowledge is a gamified baseball trivia web application where users can test and share their baseball knowledge by completing collections of history quizzes. Authenticated users can set their favorite team compete in leader boards.</p>
                        <p className="tech"><span>Technology:</span> Laravel, PHP, MySQL, AWS, Git </p> 
                        <p className="tech"><span>Live URL:</span> <a href="https://mlbknowledge.com" target="_blank" rel="noreferrer">mlbknowledge.com</a></p> 
                        <p className="tech"><span>Role:</span> Lead Developer</p>
                    </div> 
                </div>
                <div className="cell large-6">
                    <div className="image-cell">
                        <SimpleSlider7 />
                    </div> 
                </div>
            </div>
            <div className="grid-x work-item">
                <div className="cell large-6 small-order-1 large-order-2">
                    <div className="inner-cell">
                        <h3>Red Bird Trivia</h3>
                        <p>Red Bird Trivia is a gamified baseball trivia web application focused on the history of the St. Louis Cardinals.</p>
                        <p className="tech"><span>Technology:</span> React, Laravel, Git </p> 
                        <p className="tech"><span>Live URL:</span> <a href="https://redbirdtrivia.com" target="_blank" rel="noreferrer">redbirdtrivia.com</a></p> 
                        <p className="tech"><span>Role:</span> Lead Developer</p>
                    </div> 
                </div>
                <div className="cell large-6 small-order-1 large-order-1">
                    <div className="image-cell">
                        <SimpleSlider8 />
                    </div> 
                </div>
            </div>
            <div className="grid-x work-item">
                <div className="cell large-6">
                    <div className="inner-cell">
                        <h3>Perfect Golf Event 2.0</h3>
                        <p>A rebuild and redesign of Perfect Golf Event's organizer portal. Golf tournament organizer's 
                            can set up and manage registration packages, payment gateways, golf courses, and more. The website builder 
                            tool creates the data used on the tournament website.
                        </p>
                        <p className="tech"><span>Technology:</span> CodeIgniter, PHP, MySQL, Zurb Foundation, AWS, Git </p> 
                        <p className="tech"><span>Live URL:</span> <a href="https://app.perfectgolfevent.com" target="_blank" rel="noreferrer">app.perfectgolfevent.com</a> (Note: Limited access without account)</p> 
                        <p className="tech"><span>Role:</span> Lead Developer and Project Manager</p>
                    </div> 
                </div>
                <div className="cell large-6">
                    <div className="image-cell">
                        <SimpleSlider1 />
                    </div> 
                </div>
            </div>
            <div className="grid-x work-item">
                <div className="cell large-6 small-order-2 large-order-1">
                    <div className="image-cell">
                        <SimpleSlider2 />
                    </div> 
                </div>
                <div className="cell large-6 small-order-1 large-order-2">
                    <div className="inner-cell">
                        <h3>Perfect Golf Event Tournament Sites</h3>
                        <p>A React based application using the subdomain slug as the identifier for the golf event. All website content 
                            and registration packages are pulled in via an API from the 2.0 web app (see above). Registrants can then securely 
                            pay for for one or multiple registration packages and manage the golfers.
                        </p>
                        <p className="tech"><span>Technology:</span> React.js, Redux, Bootstrap, AWS, Git </p> 
                        <p className="tech"><span>Live URLs to sample sites: </span> <a href="https://perfect-golf-event-sample-site-1.perfectgolfevent.com/" target="_blank" rel="noreferrer">Sample Site 1</a>, <a href="https://project-change-bricks-for-balls-1st-annual-golf-tournament.perfectgolfevent.com/" target="_blank"  rel="noreferrer">Sample Site 2</a>, <a href="https://perfect-golf-event-sample-site-3.perfectgolfevent.com/" target="_blank" rel="noreferrer">Sample Site 3</a></p>
                        <p className="tech"><span>Role:</span> Lead Developer and Project Manager</p>
                    </div>
                </div>
            </div>
            <div className="grid-x work-item">
                <div className="cell large-6">
                    <div className="inner-cell">
                        <h3>PerfectGolfEvent.com</h3>
                        <p>The main marketing website for Perfect Golf Event was built on WordPress with a custom theme based on Foundation. WooCommerce is used for the Tournament Shop.
                        </p>
                        <p className="tech"><span>Technology:</span> WordPress, WooCommerce, Zurb Foundation, AWS, Git </p> 
                        <p className="tech"><span>Live URL:</span> <a href="https://perfectgolfevent.com" target="_blank" rel="noreferrer">perfectgolfevent.com</a></p> 
                        <p className="tech"><span>Role:</span> Developer</p>
                    </div> 
                </div>
                <div className="cell large-6">
                    <div className="image-cell">
                        <SimpleSlider3 />
                    </div> 
                </div>
            </div>
            <div className="grid-x work-item">
                <div className="cell large-6  small-order-2 large-order-1">
                    <div className="image-cell">
                        <SimpleSlider4 />
                    </div> 
                </div>
                <div className="cell large-6 small-order-1 large-order-2">
                    <div className="inner-cell">
                        <h3>Beckwood Press</h3>
                        <p>The corporate site for Beckwood Press, a large scale machinery manufacturer based in St. Louis.</p>
                        <p className="tech"><span>Technology:</span> WordPress, Zurb Foundation, jQuery, Git </p> 
                        <p className="tech"><span>Live URL:</span> <a href="https://beckwoodpress.com" target="_blank" rel="noreferrer">beckwoodpress.com</a></p> 
                        <p className="tech"><span>Role:</span> Developer</p>
                    </div> 
                </div>
            </div>
            <div className="grid-x work-item">
                <div className="cell large-6">
                    <div className="inner-cell">
                        <h3>Tech Electronics</h3>
                        <p>The corporate site for Tech Electronics, a midwest based technology service company. The site was built on the WordPress platform with the Foundaton framework.
                        </p>
                        <p className="tech"><span>Technology:</span> WordPress, Foundation, jQuery, Git </p> 
                        <p className="tech"><span>Live URL:</span> <a href="https://techelectronics.com" target="_blank" rel="noreferrer">techelectronics.com</a></p> 
                        <p className="tech"><span>Role:</span> Developer</p>
                    </div> 
                </div>
                <div className="cell large-6">
                    <div className="image-cell">
                        <SimpleSlider5 />
                    </div> 
                </div>
            </div>
     

            <div className="skills">
                <h3>Skills and Experience</h3>
                <div className="grid-x revealer">
                    <div className="cell medium-3"><span>Apache</span></div>
                    <div className="cell medium-3"><span>Linux</span></div>
                    <div className="cell medium-3"><span>SSH</span></div>
                    <div className="cell medium-3"><span>AWS</span></div>
                </div>
                <div className="grid-x revealer">
                    <div className="cell medium-3"><span>RWD</span></div>
                    <div className="cell medium-3"><span>E-Commerce</span></div>
                    <div className="cell medium-3"><span>APIs</span></div>
                    <div className="cell medium-3"><span>HTML5</span></div>
                </div>
                <div className="grid-x revealer">
                    <div className="cell medium-3"><span>CSS3</span></div>
                    <div className="cell medium-3"><span>Sass</span></div>
                    <div className="cell medium-3"><span>Less</span></div>
                    <div className="cell medium-3"><span>XML</span></div>
                </div>
                <div className="grid-x revealer">
                    <div className="cell medium-3"><span>AJAX</span></div>
                    <div className="cell medium-3"><span>JSON</span></div>
                    <div className="cell medium-3"><span>PHP</span></div>
                    <div className="cell medium-3"><span>Laravel</span></div>
                </div>
                <div className="grid-x revealer">
                    <div className="cell medium-3"><span>JavaScript</span></div>
                    <div className="cell medium-3"><span>jQuery</span></div>
                    <div className="cell medium-3"><span>React</span></div>
                    <div className="cell medium-3"><span>JSX</span></div>
                </div>
                <div className="grid-x revealer">
                    <div className="cell medium-3"><span>Jira</span></div>
                    <div className="cell medium-3"><span>WordPress</span></div>
                    <div className="cell medium-3"><span>Drupal</span></div>
                    <div className="cell medium-3"><span>Adobe Creative Suite</span></div>
                </div>
         
            
            </div>
        </div>
    );
  }
}

