import React, { Component } from 'react';
export default class About extends Component {
  render() {
    return (
      <div id="about-section" className="">
        <div className="grid-x">
            <div className="cell">
                <h2>About</h2>
                <p>Since graduating with a BA in Mass Communictions at the University of Missouri-Saint Louis, Michael has witnessed the evolution of web development resulting in broad range of knowledge and experience.</p>
                <p>Michael's web development career includes work with major brands like Intuit, Fidelity, Emerson, Washington University, and as a long list of consultant work with start-ups and design agencies in the US. The combination of Michael's web development background and project management skills make him uniquely qualified for the right role.</p>
            </div>
        </div>
        
      </div>
    );
  }
}
