import React, {} from 'react';
import logo from './logo.svg';
import './dist/App.css';
import Header from './components/Header';
import Hero from './components/Hero';
import Work from './components/Work';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';
import ReactGA from 'react-ga';

ReactGA.initialize('GTM-N5RCBKL', []);

function App() {

  return (
    <div className="App">
      <Header logo={logo}/>
      <Hero />
      <Work />
      <About />
      <Contact />      
      <Footer />

    </div>
  );
}

export default App;
